import React from 'react'
import {GatsbyImage} from 'gatsby-plugin-image'

import Container from './container'
import * as styles from './download-container.module.css'
import {Link} from "gatsby";
import DonateButton from "./donate-button";

const DownloadContainer = ({url, image}) => {
    if (!url) return null
    if (!image) return null

    return (
        <Container>
            <div className={styles.row}>
                <div className={styles.column}>
                    <div className={styles.textColumn}>
                        <div className={styles.text}>Leuk dat je dit patroon wilt gebruiken! Het is uiteraard
                            helemaal
                            gratis, maar als je er erg blij mee bent zou ik het hartstikke leuk vinden als je een
                            kleine
                            donatie zou doen. Dat helpt mij om deze site in de lucht te houden. Dankjewel alvast!
                        </div>
                        <div className={styles.buttonContainer}>
                            <DonateButton buttontext="DONEER 🖤"/>
                        </div>
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.iconText}>Klik op het icoon hieronder om het patroon te downloaden:</div>
                    <div className={styles.icon}>
                        <Link to={`https:${url}`} target="_blank">
                            <GatsbyImage alt="" image={image}/>
                        </Link>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default DownloadContainer
