// create download content model on contentful
// Left: text do you want to donate?
// Right: pdf icon to download
// Page works - only need to do the markup now


import React from 'react'
import {graphql} from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import DownloadContainer from "../components/download-container";
import Hero from "../components/hero";


class DownloadPageTemplate extends React.Component {
    render() {
        const download_info = get(this.props, 'data.contentfulDownload')

        return (
            <Layout location={this.props.location}>
                <Seo
                    title={download_info.title}
                    description="download_info"
                />
                <Hero title={download_info.title}/>
                <DownloadContainer image={download_info.icon.gatsbyImageData}
                                   url={download_info.file.file.url}/>
            </Layout>
        )
    }
}

export default DownloadPageTemplate

export const pageQuery = graphql`
    query DownloadBySlug(
         $slug: String!
    ) {
      contentfulDownload(slug: {eq: $slug }) {
        slug
        title
        file {
          file {
            url
          }
        }
        icon {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
            resize(height: 630, width: 1200) {
              src
            }
        }
      }
    }
`
