import React from "react";

import Container from "./container";
import * as styles from "./donate-button.module.css";


const DonateButton = ({buttontext}) => {
    if (!buttontext) return null;

    return (
        <form action="/donate">
            <button
                className={styles.donateButton}
            >
                {buttontext}
            </button>
        </form>
    );
};

export default DonateButton;
